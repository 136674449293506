.site-layout .site-layout-background {
  background: #fff;
}

thead[class*="ant-table-thead"] th {
  background-color: #b7d4ff !important;
}

.container {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../../assets/background.jpg") !important;
  background-size: cover;

  background-color: purple;
}
