.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #f0f2f5;
}
[data-theme="dark"] .site-layout .site-layout-background {
  background: #141414;
}
